import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/pages/home/home";
import OurClients from "./components/pages/our-clients/OurClients";
import Navbar from "./components/layout/Navbar/Navbar";
import Portfolio from './components/pages/portfolio/Portfolio';
import PortfolioInner from "./components/pages/portfolio/portfoliodetails/PortfolioDetails";
import Blogs from "./components/pages/blogs/Blogs";
import Footer from "./components/layout/footer/Footer";
import Services from "./components/pages/services/Services";
import ContactUs from "./components/pages/contactus/ContactUs";
import BlogsDetails from "./components/pages/blogs/blogsdetails/BlogsDetails";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navbar />}>
                    <Route index element={<Home />} />
                    <Route path="/our-clients" element={<OurClients />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/portfolio/:id" element={<PortfolioInner />} />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/blogs/:id" element={<BlogsDetails />} />
                </Route>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
