import React, { useEffect } from "react";
import "./who.scss";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

const WhoWeAre = () => {
  useEffect(() => {
    AOS.init({
      //  duration : 5000
    });
  }, []);

  
  const { t } = useTranslation();
  return (
    <>
      <div className="whowemain pt-5 desktop pb-5" dir={t("ltr")}>
        <div className="header d-flex justify-content-center align-items-center " data-aos="fade-right">
          <div className="background-header  mb-5" >
            <h1>{t("WHO WE ARE")}</h1>
          </div>
          <h2>{t("WHO WE ARE")}</h2>
        </div>

        <Row className="mt-5 mb-5 d-flex justify-content-center">
          <Col xl="6" xs="11" className="mt-5">
          <div className = "d-flex justify-content-center align-items-center" data-aos="fade-up">
              <p className="whoweare-text w-75">{t("WHO WE ARE DESC")}</p>
            </div>
          </Col>
          <Col xl="6" className="mt-5 whoweare-text-container">
          <div className = "d-flex flex-column justify-content-center align-items-center" data-aos="fade-right">
              <h2 className=" w-100">{t("Elevate your Brand with Rayat Marketing")}</h2>
              <div className="d-flex justify-content-start align-items-center w-100">
                <h1 className="seventeen-years">17</h1>
                <h2>{t("years filled")}<br/>{t("with unparalleled expertise")}</h2>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="whowemain pt-5 mobile  bg-black">
        <div className="header d-flex justify-content-center align-items-center">
          <div className="background-header  mb-5">
            <h1>{t("WHO WE ARE")}</h1>
          </div>
          <h2>{t("WHO WE ARE")}</h2>
        </div>

        <Row className="mt-5 mb-5 d-flex justify-content-center">
          <Col xl="6" xs="11" className="mt-5">
          <div className = "d-flex justify-content-center align-items-center" data-aos="zoom-in-right">
              <p className="whoweare-text w-75">{t("WHO WE ARE DESC")}</p>
            </div>
          </Col>
          <Col xl="6" className="mt-5 whoweare-text-container">
          <div className = "d-flex flex-column justify-content-center align-items-center" data-aos="fade-right">
              <h2 className=" w-100">{t("Elevate your Brand with Rayat Marketing")}</h2>
              <div className="d-flex justify-content-start align-items-center w-100">
                <h1 className="seventeen-years">17</h1>
                <h2>years of <br/>the best experience</h2>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default WhoWeAre;
