import React, { useEffect} from "react";
import "./portfolioinner.scss";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";
import {Link} from 'react-router-dom';
import ReactPlayer from "react-player";
import {data} from "../../../../portfolio/projects";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

const PortfolioInner = () => {
  const { t } = useTranslation();
  
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      AOS.init({
        //  duration : 5000
      });
    });

  const { id } = useParams();
  const subject = data.find((subject) => subject.id === id);

  const settings = {
    dots: false,
    slidesToShow: 6,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="portfolioinner">
        <img src={subject.banner} alt="banner" className="w-100 banner-height"/>
      </div>
      <div className="portfolioinner-text pb-5 pt-5">
        <div className="portfolio-desc">
          <div data-aos="fade-right" className="w-100 d-flex flex-column justify-content-center align-items-center title">
            <div className="w-75" dir={t('ltr')}>
            {t('ltr')==='ltr'?
            <h1 className="ps-5 mb-5">{subject.name}</h1>:
            <h1 className="ps-5 mb-5 text-end">{subject.arabic_name}</h1>}
            <h4>{t('ltr')==='ltr'?subject.work_title:subject.work_title_arabic}</h4>
            <p>{t('ltr')==='ltr'?subject.work_description:subject.work_description_arabic}</p>
          </div>
          </div>
        <div className="w-100 d-flex justify-content-center align-items-center image mb-5" data-aos="fade-right">
        <img src={subject.image} alt="ala Keifak" className="w-50 rounded max-width" />
        </div>
          <div className="footer d-flex justify-content-around align-items-center mt-5" dir={t('ltr')}>
            <img src={subject.image2} alt="alakefak2" className="w-25 rounded"  data-aos="fade-right"/>
            <h2  data-aos="fade-right">{t('scope of work')}</h2>
            <ul  data-aos="fade-right">
              {t('ltr')==='ltr'?subject.scope_of_work.map((item, index) => (
                <li>{item}</li>
              )):subject.scope_of_work_arabic.map((item, index) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
          </div>
          {subject.video===""?
           <div className="w-100 d-flex align-items-center justify-content-around images-section">
         <img src={subject.image3} alt="alakefak2" className="w-25 rounded" />
         <img src={subject.image4} alt="alakefak2" className="w-25 rounded"/>
         <img src={subject.image5} alt="alakefak2" className="w-25 rounded" />
           </div>:  
        <div className="w-100 d-flex align-items-center justify-content-around">
        <ReactPlayer
              url={subject.video}
              width="25%"
              height="100%"
              controls={true}
              loop={true}
              playing={true}
            />
            <ReactPlayer
              url={subject.video2}
              width="25%"
              height="100%"
              controls={true}
              loop={true}
              playing={false}
            />
            <ReactPlayer
              url={subject.video3}
              width="25%"
              height="100%"
              controls={true}
              loop={true}
              playing={false}
            />
        </div>
        }

        <div className="mt-5 w-100 d-flex justify-content-center align-items-center mb-5 portfolio">
          <div className="w-75">
            <Slider {...settings} className="h-100">
            {data.map((item,index)=>(
             
               <div className="d-flex flex-column justify-content-center align-items-center ">
                <Link to={`/portfolio/${item.id}`} key={item.id} className="d-flex flex-column justify-content-center align-items-center">
                 <img src={item.image2} alt='portfolio' className="w-75 carousel-image rounded"/>
                 <div className="h-25 bg-light w-75 text-center rounded pt-3 pb-3 d-flex justify-content-center align-items-center">
                   <h6 className="p-0 m-0">{item.name}</h6>
                 </div>
                 </Link>
               </div>
            ))}
              
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioInner;
