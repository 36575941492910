import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
//react UI
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import rayatLogo from "../../../assets/rayatlogo.svg";
//component
import "./Navbar.scss";
import instagram from '../../../assets/instagram.svg';
import twitter from '../../../assets/twitter.svg';
import linkedin from '../../../assets/linkedin.svg';
import tiktok from '../../../assets/tiktok.svg';

function Navbar() {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const handleClick = () => {
    setIsActive(!isActive);
    setIsOpen(!isOpen);
    window.scrollTo(0, 0);
  };

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  return (
    <>
      {" "}
      <div className="d-flex justify-content-center align-items-center w-100 rayatLogo">
        <a href="/">
          <img src={rayatLogo} alt="rayatlogo" />
        </a>
      </div>
        <div className="localization">
        <Form.Select
          aria-label="Default select example"
          onChange={changeLanguage}
        >
          <option value="en">English</option>
          <option value="ar">Arabic</option>
        </Form.Select>
        </div>
        <div
          id="toggle"
          className={isActive ? "active button_container" : "button_container"}
          onClick={handleClick}
        >
          <span class="top"></span>
          <span class="middle"></span>
          <span class="bottom"></span>
        </div>
      <div
        id="overlay"
        className={
          isOpen
            ? "open overlay d-flex justify-content-start align-items-center flex-column"
            : "overlay d-flex justify-content-start align-items-center flex-column"
        }
      >
         
        <nav className="overlay-menu text-start w-75">
        <div className="localization-mobile">
        <Form.Select
          aria-label="Default select example"
          onChange={changeLanguage}
        >
          <option value="en">English</option>
          <option value="ar">Arabic</option>
        </Form.Select>
        </div>
          <ul>
            
            <li>
              <Link to="/" onClick={handleClick}>
                {t('WHO WE ARE')}
              </Link>
            </li>

            <li>
              <Link to="/services" onClick={handleClick}>
                 {t('SERVICES')}
              </Link>
            </li>
            <li>
              <Link to="/portfolio" onClick={handleClick}>
                {t('PORTFOLIO')}
              </Link>
            </li>
            <li>
              <Link to="/our-clients" onClick={handleClick}>
                {t('OUR CLIENTS')}
              </Link>
            </li>
            <li>
              <Link to="/blogs" onClick={handleClick}>
                 {t('BLOGS')}
              </Link>
            </li>
            <li>
              <Link to="/contact-us" onClick={handleClick}>
                {t('CONTACT US')}
              </Link>
            </li>
          </ul>
        </nav>
        <div className='mobile-social '>
        <a href='https://instagram.com/rayatmarketing?igshid=MzRlODBiNWFlZA==' className='mb-4'>
                <img src={instagram} alt='ig icon'/>
              </a>
              <a href='https://twitter.com/rayatmarketing?s=11&t=ZUlDMnfoVx_5jMItXaxV3w' className='mb-4'>
                <img src={twitter} alt='ig icon'/>
              </a>
              <a href='https://sa.linkedin.com/company/rayatmarketing' className='mb-4'>
                <img src={linkedin} alt='ig icon'/>
              </a>
              <a href='https://www.tiktok.com/@rayatmarketing?_t=8eBfz2KQhxi&_r=1' className='mb-2'>
                <img src={tiktok} alt='ig icon'/>
              </a>
        </div>
      </div>
      
      <Outlet />
    </>
  );
}

export default Navbar;
