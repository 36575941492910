import React from "react";
import "./blogsdetails.scss";
import headerBG from '../../../../assets/header.png';
import arrow from '../../../../assets/arrow.svg';
import { useTranslation } from "react-i18next";
import {data} from './blogsdata';
import { useParams } from "react-router-dom";

const BlogsDetails = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const subject = data.find((subject) => subject.id === id);

  return (
    <>
    <div className="desktop">
      <div className="blogs-details">
      <div className="d-flex flex-column h-100 w-100 header-bg">
      <div className="w-75 mt-5 ms-5 hidden">
        <div className="header-social">
          <img src={headerBG} alt="header"/>
        </div>
      </div>
      </div>
      </div>
      </div>
      <div className="mobile h-100">
      <div className="blogs">
      <div className="d-flex flex-column h-100 w-100 header-bg">
      <div className="w-75 mt-5 ms-2 ">
        <div className="header-social mt-5">
          <img src={headerBG} alt="header"/>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div className="w-75 d-flex flex-column align-items-center mt-5 header-text" dir={t("ltr")}>
        <h1>{t("Have an idea or project?")}</h1>
        <h1>{t("lets talk")}</h1>
        <div className="d-flex m-5">
          <img src={arrow} alt='arrow' className={t("ltr")==="rtl"? "transform-img ms-3":''}/>
          <h6 className="ms-3">{t("lets be in touch")}</h6>
        </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center blog-body pb-5">
        <div className="w-75 d-flex flex-column justify-content-center align-items-center">
        <h1>{subject.name}</h1>
        {subject.banner!==""?
        <img src={subject.banner} alt="banner" className="w-50 mb-5"/>:""}
        <div className="d-flex justify-content-start align-items-start flex-column" dir={subject.lang==="ar"?"rtl":"ltr"}>
        {subject.text.map((item,index)=>(
          <>
           <div dangerouslySetInnerHTML={{ __html: item.title }} /> 
           <div dangerouslySetInnerHTML={{ __html: item.description }} className="mb-3"/> 
          </>
        ))}
        </div>
        </div>
      </div>
    </>
  );
};

export default BlogsDetails;
