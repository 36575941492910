import React, {useEffect} from "react";
import "./ourteam.scss";
import { Row, Col, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

const OurTeam = () => {
    useEffect(() => {
        AOS.init({
          //  duration : 5000
        });
      }, []);
 
  const { t } = useTranslation();
  return (
    <>
      <div className="ourteam pt-5 pb-5 d-flex flex-column justify-content-center align-items-center">
        <div className="ourteam-header d-flex justify-content-center align-items-center " >
          <div className="background-header  mb-5">
            <h1>{t("OUR SKILLS")}</h1>
          </div>
          <div data-aos="fade-right">
          <h2>{t("OUR SKILLS")}</h2>
          </div>
        </div>
        <div className="carousel-row ">
          <Row className="mt-5 w-100 pt-5">
            <Col xl="12" className="pe-5 ps-5">
              <div className="d-flex justify-content-between">
                <h3>GRAPHIC DESIGNING</h3>
                <h3>90%</h3>
              </div>
              <div className="progressBar" data-aos="fade-right">
                <ProgressBar now={90} animated variant="info" />
              </div>
            </Col>
          </Row>
          <Row className="mt-4 w-100">
            <Col xl="12" className="pe-5 ps-5">
              <div className="d-flex justify-content-between">
                <h3>EVENTS MANAGEMENT</h3>
                <h3>100%</h3>
              </div>
              <div className="progressBar" data-aos="fade-right">
                <ProgressBar now={100} animated variant="info" />
              </div>
            </Col>
          </Row>
          <Row className="mt-4 w-100">
            <Col xl="12" className="pe-5 ps-5">
              <div className="d-flex justify-content-between">
                <h3>PHOTOSHOOTING</h3>
                <h3>93%</h3>
              </div>
              <div className="progressBar" data-aos="fade-right">
                <ProgressBar now={93} animated variant="info" />
              </div>
            </Col>
          </Row>
          <Row className="mt-4 w-100 ">
            <Col xl="12" className="pe-5 ps-5">
              <div className="d-flex justify-content-between">
                <h3>MARKET RESEARCHING</h3>
                <h3>97%</h3>
              </div>
              <div className="progressBar" data-aos="fade-right">
                <ProgressBar now={97} animated variant="info" />
              </div>
            </Col>
          </Row>
          <Row className="mt-4 w-100 pb-5">
            <Col xl="12" className="pe-5 ps-5" >
              <div className="d-flex justify-content-between">
                <h3>STRATEGIC CONSULTING</h3>
                <h3>98%</h3>
              </div>
              <div className="progressBar" data-aos="fade-right">
                <ProgressBar now={98} animated variant="info" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
