import React from "react";
import "./portfoliocarousel.scss";
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import {data} from "../../../../portfolio/projects";

const Clients = () => {
  console.log(data)
  const settings = {
    dots: false,
    slidesToShow: 3,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="portfoliomain pt-5 d-flex flex-column justify-content-center align-items-center w-100">
        <div className="header d-flex justify-content-center align-items-center ">
          <div className="background-header  mb-5">
            <h1>{t("PORTFOLIO")}</h1>
          </div>
          <h2>{t("PORTFOLIO")}</h2>
        </div>
        <div className="mt-5 w-75 mb-5 portfolio">
          <Slider {...settings} className="h-100">
            {data.map((item,index)=>(
               <div className="d-flex flex-column justify-content-center align-items-center ">
                <Link to={`/portfolio/${item.id}`} key={item.id} className="d-flex flex-column justify-content-center align-items-center">
                 <img src={item.image2} alt='portfolio' className="w-75 carousel-image rounded"/>
                 <div className="h-25 bg-light w-75 text-center rounded pt-3 pb-3 d-flex justify-content-center align-items-center">
                   <h6 className="p-0 m-0">{item.name}</h6>
                 </div>
                 </Link>
               </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Clients;
