import React, {useEffect} from "react";
import "./premservices.scss";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

const PremServices = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
       duration : 1000
    });
  }, []);

  return (
    <>
      <div className="premservices pt-5 pb-5">
        <div className="header d-flex justify-content-center align-items-center ">
          <div className="background-header  mb-5">
            <h1>{t("SERVICES")}</h1>
          </div>
          <h3>{t("PREM SERVICES")}</h3>
        </div>
      </div>
      <div className="premservices-services d-flex flex-column justify-content-center align-items-center">
        <Row className="w-75 mt-5 d-flex justify-content-center">
          <Col xl="10" dir={t("ltr")} className="services-text  p-3" data-aos="zoom-in">
          <h5 className="mb-3 digital">{t("PRODUCING PROMOTIONAL AND ADVERTISING MATERIALS")}</h5>
            <ul className="list">
              <li> {t("marketing booths")}</li>
              <li> {t("promotional gifts")}</li>
              <li> {t("road shows")}</li>
              <li> {t("corporate conferences")}</li>
            </ul>
          </Col>
        </Row>
        <Row className="w-75 d-flex justify-content-center mb-5">
          <Col xl="5" dir={t("ltr")} className="services-text me-3 ms-3 mt-3 p-3" data-aos="zoom-in">
          <h5 className="mb-3 branding">{t("DIGITAL MARKETING")}</h5>
            <ul className="list">
              <li> {t("building")}</li>
              <li> {t("social media")}</li>
              <li> {t("campaign")}</li>
              <li> {t("visual")}</li>
            </ul>
          </Col>
          <Col xl="5" dir={t("ltr")} className="services-text p-3 mt-3" data-aos="zoom-in">
            <h5 className="mb-3 pictures">{t("MARKETING PERSONNEL")}</h5>
            <ul className="list">
              <li> {t("promoters")}</li>
              <li> {t("Merchandisers")}</li>
              <li> {t("Market Research")}</li>
              <li> {t("Field Survey")}</li>
            </ul>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PremServices;
