import React from "react";
import { Col, Row} from "react-bootstrap";
import RayatLogo from "../../../assets/rayatlogoblack.svg";
import { MdOutlineMail } from 'react-icons/md';
import {FaMobileAlt } from 'react-icons/fa';
import {BiCurrentLocation} from 'react-icons/bi';
import "./footer.scss";

function Footer() {
  return (
    <>
      <div className="footer-main d-flex flex-column justify-content-center align-items-center">
        <Row className="w-100 mt-2 mb-2">
          <Col xl="6" className="d-flex flex-column justify-content-center align-items-center">
            <div className="w-75 mb-3">
            <img src={RayatLogo} alt="rayatlogo" className="w-75"/>
            </div>
            <p className="w-75">Rayat Marketing is a part of Rayat Group, and it’s one of the leading marketing agencies in the Middle East </p>
          </Col>
         
          <Col xl="4"  className="d-flex flex-column justify-content-center h-100 mt-5 align-items-center" >
            <div >
               <p className="mb-3"><MdOutlineMail className="me-3"/>riyadh@rayat.com</p>
               <p className="mb-3"><FaMobileAlt className="me-3"/>+966114500410</p>
               <p className="mb-3"><FaMobileAlt className="me-3"/>+966535444162</p>
               <p><BiCurrentLocation className="me-3"/>Intersection of Othman Bin Affan road and North Ring Road, El-Habdan Bld., 1st Floor, Office no.1, Riyadh 11372, Saudi Arabia</p>  
            </div>
          </Col>
          {/* <Col xl="4" className="d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column icons">
                <h6 className="mb-5"><a href="https://instagram.com/rayatmarketing?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">INSTAGRAM</a></h6>
                <h6 className="mb-5"><a href="https://twitter.com/rayatmarketing?s=11&t=ZUlDMnfoVx_5jMItXaxV3w" target="_blank" rel="noreferrer">TWITTER</a></h6>
                <h6 className="mb-5"><a href="https://sa.linkedin.com/company/rayatmarketing" target="_blank" rel="noreferrer">LINKEDIN</a></h6>
                <h6 className="mb-3"><a href="https://www.tiktok.com/@rayatmarketing?_t=8eBfz2KQhxi&_r=1" target="_blank" rel="noreferrer">TIKTOK</a></h6>
            </div>
          </Col> */}
        </Row>
      </div>
    </>
  );
}

export default Footer;
