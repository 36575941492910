import React from "react";
import tower from "../../../assets/contactus.png";
import letstalk from "../../../assets/letstalkcontact.svg";
import Form from "react-bootstrap/Form";
import letstalkimg from "../../../assets/letstalkbg.png";
import "./contactus.scss";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <>
    <div className="desktop">
      <div className="contact">
        <div className="d-flex position-relative h-100 justify-content-center align-items-center bg-black">
          <div className="tower-bg">
            <img src={tower} alt="tower bg" />
          </div>
          <div className="contact-right w-100 d-flex flex-column justify-content-center align-items-center h-75">
            <img src={letstalk} alt="let's talk" className="mb-5" />
            <p>
              <span className="colored-text">{t('contact us text1')}</span>
              {t('contact us text2')}
            </p>
            <div className="form d-flex flex-column w-75 mt-5">
              <Form>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Control type="text" placeholder="Name *" />
                </Form.Group>
                <Form.Group className="mb-3 mt-5" controlId="email">
                  <Form.Control type="email" placeholder="Email Address *" />
                </Form.Group>
                <Form.Group className="mb-3 mt-5" controlId="message">
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Message *"
                  />
                </Form.Group>
              </Form>
              <div className="submit-button mt-5">
                <button className="w-100 p-3">Let's Talk</button>
              </div>
            </div>
          </div>
          <div className="lets-talk-img">
                <img src={letstalkimg} alt="lets talk"/>
          </div>
        </div>
      </div>
      </div>
      <div className="mobile">
      <div className="contact">
        <div className="d-flex flex-column position-relative h-100 justify-content-center align-items-center bg-black">
          <div className="tower-bg">
            <img src={tower} alt="tower bg" />
          </div>
          <div className="contact-right w-100 d-flex flex-column justify-content-center align-items-center h-75">
            <img src={letstalk} alt="let's talk" className="mb-5 w-100" />
            <p>
              <span className="colored-text">{t('contact us text1')}</span>
              {t('contact us text2')}
            </p>
            <div className="form form-mobile d-flex flex-column w-75 mt-5">
              <Form>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Control type="text" placeholder="Name *" />
                </Form.Group>
                <Form.Group className="mb-3 mt-5" controlId="email">
                  <Form.Control type="email" placeholder="Email Address *" />
                </Form.Group>
                <Form.Group className="mb-3 mt-5" controlId="message">
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Message *"
                  />
                </Form.Group>
              </Form>
              <div className="submit-button mt-5">
                <button className="w-100 p-3">Let's Talk</button>
              </div>
            </div>
          </div>
          <div className="lets-talk-img">
                <img src={letstalkimg} alt="lets talk" />
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default ContactUs;
