import React from "react";
import "./ourblogs.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useTranslation } from "react-i18next";

const OurBlogs = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="blogsmain pt-5 d-flex flex-column justify-content-center align-items-center w-100">
                <div className="header d-flex justify-content-center align-items-center ">
                    <div className="background-header  mb-5">
                        <h1>{t("OUR BLOGS")}</h1>
                    </div>
                    <h2>{t("BLOGS")}</h2>
                </div>
            </div>
            
        </>
    );
};

export default OurBlogs;
