import React, {useEffect, useState} from "react";
import "./home.scss";
import WhoWeAre from "./components/whoweare/WhoWeAre";
import OurTeam from "./components/ourteam/OurTeam";
import headerBG from "../../../assets/header.png";
import arrow from "../../../assets/arrow.svg";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import RayatVideo from "../../../assets/rayat.mp4";

const Home = () => {
  const { t } = useTranslation();
  const [desktop, setDesktop] = useState(true);

  useEffect(()=>{
    if(window.innerWidth<767){
      setDesktop(false)
    }else{setDesktop(true)}
  },[])

  return (
    <>
      <div className="main bg-black">
        <div className="desktop">
          {desktop?
          <div className="video">
            <ReactPlayer
              url={RayatVideo}
              width="100%"
              height="100%"
              controls={false}
              loop={true}
              playing={true}
            />
          </div>:<></>}
        </div>
        {/* <div className="desktop">
          <div className="d-flex flex-column h-100 w-100 ">
            <div className="w-75 mt-5 ms-5">
              <div className="header-social">
                <img src={headerBG} alt="header" />
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div
                className="w-75 d-flex flex-column align-items-start mt-5 header-text"
                dir={t("ltr")}
              >
                <h1>{t("Have an idea or project?")}</h1>
                <h1>{t("lets talk")}</h1>
                <div className="d-flex m-5">
                  <img
                    src={arrow}
                    alt="arrow"
                    className={t("ltr") === "rtl" ? "transform-img ms-3" : ""}
                  />
                  <h6 className="ms-3">{t("lets be in touch")}</h6>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="mobile h-100">
          <div className="d-flex flex-column h-100 w-100">
            <div className="w-75 mt-5 ms-2">
              <div className="header-social mt-5 hidden">
                <img src={headerBG} alt="header" />
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center mt-5 ">
              <div
                className="w-75 d-flex flex-column align-items-center justify-content-center mt-5 header-text"
                dir={t("ltr")}
              >
                <h1>{t("Have an idea or project?")}</h1>
                <h1>{t("lets talk")}</h1>
                <div className="d-flex m-5">
                  <img
                    src={arrow}
                    alt="arrow"
                    className={t("ltr") === "rtl" ? "transform-img ms-3" : ""}
                  />
                  <h6 className="ms-3">{t("lets be in touch")}</h6>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <WhoWeAre />
      <OurTeam />
    </>
  );
};

export default Home;
