export const data =
   [
    {
        "id":"1",
        "name":"Ala Kaifak",
        "arabic_name": "على كيفك",
        "scope_of_work_arabic":[
            " إدارة منصات التواصل االجتماعي",
            "إنشاء محتوى إبداعي",
            " صناعة محتوى للمنصات",
            "إنشاء تصاميم منصات تواصل اجتماعي",
            "إنتاج فيديو",
            "إنشاء حمالت إعالنية",
            "إدارة التواصل على المنصات"
        ],
        "work_title_arabic": "حملة رمضان 2023 لهدف من الحملة نشر الوعي بالعلامة التجارية واظهار تنوع وجودة المنتجات في معارض على كيفك من خلال قيامنا بتصوير وإنتاج فيديوهات بسيناريوهات ابداعية لعروض شهر رمضان المبارك، بالاضافة الى اختيار الممثلين المناسبين للحملة بهدف ايصال هدف الحملة بطريقة واضحة ومبتكرة للمستهلك ومن ثم نشر الحملة مع إدارتها ",
        "work_description_arabic": "شركة على كيفك تعمل في مجال بيع تجزئة الكماليات ومنتجات الاستهلاكية وهي امتداد مؤسسة على كيفك للتجارة , والتي تأسست في عام 1418هـ بفرع واحد وسط مدينة الرياض حيث تم عرض المنتجات الاستهلاكية بأسعار  في متناول الجميع. ",
        "scope_of_work":[
            "Managing social media platforms",
            "Create creative content",
            "Creating content for platforms",
            "Create designs for social media platforms",
            "video production",
            "Creating advertising campaigns",
            "Managing communication on platforms"
        ],
        "work_title": "Ramadan Campaign shooting :The objective of this campaign is to enhance brand awareness and demonstrate the diversity and quality of our products through customized exhibitions on the Keifak platform. To this end, we are creating and producing videos featuring creative scenarios for our Ramadan promotions. Additionally, we have carefully selected appropriate actors to effectively communicate the campaign's objectives in a clear and innovative manner to consumers. Finally, we will be managing and promoting the campaign through various channels. ",
        "work_description": "Ala Kaifak Company works in the field of retailing luxuries and consumer products. It is an extension of the Ala Kaifak Trading Establishment, which was established in 1418 AH with one branch in the center of Riyadh, where consumer products were offered at an affordable price",
        "image": require('../assets/alakefak.jpg'),
        "image2": require('../assets/alakefak2.png'),
        "video": require('../assets/alakefak1.mp4'),
        "video2": require('../assets/alakefak2.mp4'),
        "video3": require('../assets/alakefak3.mp4'),
        "banner" : require('../assets/alakeifakbanner.png')
           
    },
    {
        "id":"2",
        "name":"Luxury Parking",
        "arabic_name": "لاكجري باركنق",
        "scope_of_work_arabic":[
            " إدارة منصات التواصل الاجتماعي",
            "إنشاء محتوى إبداعي",
            " صناعة محتوى للمنصات",
            "إنشاء تصاميم منصات تواصل اجتماعي",
            "إنشاء حمالت إعالنية",
            "إدارة التواصل على المنصات"
        ],
        "work_title_arabic": " لاكجري باركنق",
        "work_description_arabic": " المنزل المثالي لسيارتك المهمة بأقصى درجات الحماية",
        "scope_of_work":[
            "Managing social media platforms",
            "Create creative content",
            "Creating content for platforms",
            "Create designs for social media platforms",
            "Creating advertising campaigns",
            "Managing communication on platforms"
        ],
        "work_title": "Luxury Parking",
        "work_description": "The perfect home for your important car with maximum protection",
        "image":  require('../assets/luxury1.jpg'),
        "image2": require('../assets/luxury2.jpg'),
        "video": "",
        "video2":"",
        "video3": "",
        "image3":require('../assets/luxury3.jpg'),
        "image4":require('../assets/luxury4.jpg'),
        "image5": require('../assets/luxury5.jpg'),
        "banner": require('../assets/luxurybanner.png')
           
    },
    {
        "id":"3",
        "name":"The National Center for Palm",
        "arabic_name": "المركز الوطني للنخيل والتمور",
        "scope_of_work_arabic":[
            "اللوحات الإرشادية الداخلية",
            "ملصقات تجارية",
            "طباعة تجارية "
        ],
        "work_title_arabic": "المركز الوطني للنخيل والتمور",
        "work_description_arabic": "تأسس المركز الوطني للنخيل والتمور بهدف إنشاء مركز متقدم للمساهمة في تطوير قطاع النخيل والتمور ، وإيجاد نظام متكامل للخدمات لتحقيق كفاءة الإنتاج وزيادة معدل استهلاك التمور السعودية محلياً وعالمياً. ",
        "scope_of_work":[
            "Interior Directional Signboards ",
            "Commercial Stickers ",
            "Commercial Printing ",
        ],
        "work_title": "The National Center for Palm and Dates",
        "work_description": "The National Center for Palm and Dates was established with the aim of establishing an advanced center to contribute to the development of the palm and dates sector, and to create an integrated system of services to achieve production efficiency and increase the rate of consumption of Saudi dates locally and globally ",
        "image":  require('../assets/national1.jpg'),
        "image2": require('../assets/national2.jpg'),
        "video": "",
        "video2":"",
        "video3": "",
        "image3":require('../assets/national3.jpg'),
        "image4":require('../assets/national4.jpg'),
        "image5": require('../assets/national5.jpeg'),
        "banner": require('../assets/nationalbanner.png')
           
    },
    {
        "id":"4",
        "name":"Vision Realization Office",
        "arabic_name": "مكتب تحقيق الرؤية",
        "scope_of_work_arabic":[
            " تصميم وتنفيذ وطباعة ملحقات الرؤية مكتب الانجاز بوزارة التربية والتعليم. "
        ],
        "work_title_arabic": "مكتب تحقيق الرؤية",
        "work_description_arabic": "مكتب تحقيق الرؤية وزارة التعليم تعتبر هذه المكاتب همزة الوصل الرئيسية بين الجهات الحكومية والجهات الداعمة ضمن منظومة تنفيذ الرؤية. يتم تنظيم الاتصال بين الاثنين من خلال عدد من المخرجات الدورية. من الناحية التنظيمية ، ترتبط المكاتب مباشرة بالجهاز الحكومي وتعمل تحت إدارتها لتحقيق هدفها المتمثل في متابعة المبادرات وتحسينها. ",
        "scope_of_work":[
            "Designing, implementing and printing accessories for the Vision Realization office in the ministry of education. "
           
        ],
        "work_title": "Vision Realization Office",
        "work_description": "These offices are considered the main link between government agencies and supporting agencies within the vision implementation system. The connection between the two is regulated by a number of cyclic outputs. From an organizational point of view, the offices are directly linked to the government apparatus and work under its management to achieve its goal of following up and improving initiatives ",
        "image":  require('../assets/vision1.jpg'),
        "image2": require('../assets/vision2.jpg'),
        "video": "",
        "video2":"",
        "video3": "",
        "image3":require('../assets/vision3.jpg'),
        "image4":require('../assets/vision4.jpg'),
        "image5": require('../assets/vision5.jpg'),
        "banner": require('../assets/visionbanner.png')
           
    },
    {
        "id":"5",
        "name":"Ahlam reves salon",
        "arabic_name": "Ahlam reves صالون ",
        "scope_of_work_arabic":[
            " إدارة منصات التواصل الاجتماعي",
            "إنشاء محتوى إبداعي",
            " صناعة محتوى للمنصات",
            "إنشاء تصاميم منصات تواصل اجتماعي",
            "إنتاج فيديو",
            "إنشاء حمالت إعالنية",
            "إدارة التواصل على المنصات"
        ],
        "work_title_arabic": "Ahlam reves صالون ",
        "work_description_arabic": "يقدم مجموعة واسعة من خدمات الصالون بأسعار معقولة. عندما تحتاج إلى تغيير، فإن صالون Reves Ahlam هو المكان المناسب للذهاب إليه ",
        "scope_of_work":[
            "Managing social media platforms",
            "Create creative content",
            "Creating content for platforms",
            "Create designs for social media platforms",
            "Video production",
            "Creating advertising campaigns",
            "Managing communication on platforms"
        ],
        "work_title": "Ahlam reves salon",
        "work_description": "The perfect home for your important car with maximum protection",
        "image":  require('../assets/ahlam1.png'),
        "image2": require('../assets/ahlam2.png'),
        "video": "",
        "video2":"",
        "video3": "",
        "image3":require('../assets/ahlam3.png'),
        "image4":require('../assets/ahlam4.png'),
        "image5": require('../assets/ahlam5.png'),
        "banner": require('../assets/ahlambanner.png')
           
    },
    {
        "id":"6",
        "name":"Intel",
        "arabic_name": "Intel",
        "scope_of_work_arabic":[
            " أكشاك التسويق واستاندات العرض. ",
            "أدارة الحدث وتنظيم فعالية",
            "ملصقات",
            "علامات الطريق",
            "خدمة الطباعة ",
        ],
        "work_title_arabic": "Intel",
        "work_description_arabic": "تبتكر Intel تقنية تغير العالم وتعمل على تحسين حياة كل شخص على هذا الكوكب. لاكثر من 50 ً عاما، كان لشركة Intel وموظفيها تأثير عميق على العالم، مما دفع األعمال والمجتمع إلى األمام من خلال ابتكار ابتكارات جذرية تحدث ثورة في الطريقة التي نعيش بها. ",
        "scope_of_work":[
            "Exhibition Stands",
            "Product Display Stands",
            "Event Management",
            "Road shows",
            "Commercial Printing"
        ],
        "work_title": "Intel",
        "work_description": "Intel create world-changing technology that improves the life of every person on the planet. Intel put the silicon in Silicon Valley. For more than 50 years, Intel and our people have had a profound influence on the world, driving business and society forward by creating radical innovation that revolutionizes the way we live.  ",
        "image":  require('../assets/intel1.jpg'),
        "image2": require('../assets/intel2.jpg'),
        "video": "",
        "video2":"",
        "video3": "",
        "image3":require('../assets/intel3.jpg'),
        "image4":require('../assets/intel4.jpg'),
        "image5": require('../assets/intel5.jpg'),
        "banner": require('../assets/intelbanner.png')
           
    },
   ]