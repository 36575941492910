import React from "react";
import "./clients.scss";
import { Row, Col } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import kpmg from '../../../../assets/kpmg.svg';
import rayatconsulting from '../../../../assets/rayatconsulting.svg';
import weqaya from '../../../../assets/weqaya.svg';
import modon from '../../../../assets/modon.svg';
import wezart from '../../../../assets/wezarat.svg';
import keefak from '../../../../assets/keefak.svg';
import intel from '../../../../assets/intel.svg';
import dptc from '../../../../assets/dptc.svg';
import shoula from '../../../../assets/shoula.svg';
import matarat from '../../../../assets/matarat.svg';
import lane from '../../../../assets/lane.svg';
import asus from '../../../../assets/asus.png';
import epson from '../../../../assets/epson.png';
import fms from '../../../../assets/fms.png';
import gaca from '../../../../assets/gaca.png';
import national from '../../../../assets/national.png';

import { useTranslation } from "react-i18next";

const Clients = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="clientmain pt-5 d-flex flex-column justify-content-center align-items-center w-100">
                <div className="header d-flex justify-content-center align-items-center ">
                    <div className="background-header  mb-5">
                        <h1>{t("OUR CLIENTS")}</h1>
                    </div>
                    <h2>{t("OUR CLIENTS")}</h2>
                </div>
                <div className="mt-5 w-75 mb-5">
                <div className="desktop">
                <Row>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={lane} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={kpmg} alt="lg logo" />
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={rayatconsulting} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={weqaya} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={modon} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={wezart} alt="lg logo"/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl="2" className="p-0">
                        <div className=" pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={keefak} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={intel} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={dptc} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={shoula} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={matarat} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={asus} alt="lg logo"/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={national} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={epson} alt="lg logo" />
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={fms} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={gaca} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                            <img src={national} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                    <div className="pt-5 pb-5 d-flex justify-content-center align-items-center client-section h-100 text-light">
                           <h3>awaiting you</h3>
                        </div>
                    </Col>
                   
                </Row>
                </div>
                <div className="mobile">
                <Row>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={lane} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={kpmg} alt="lg logo" />
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={rayatconsulting} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={weqaya} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={modon} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={wezart} alt="lg logo"/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={keefak} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={intel} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={dptc} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={shoula} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={matarat} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={asus} alt="lg logo"/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={epson} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={fms} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={national} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <img src={gaca} alt="lg logo"/>
                        </div>
                    </Col>
                    <Col xl="2" className="p-0">
                        <div className="clients-border pt-5 pb-5 d-flex justify-content-center align-items-center client-section">
                        <h3>awaiting you</h3>
                        </div>
                    </Col>
                </Row>
                </div>
            </div>
            </div>
            
        </>
    );
};

export default Clients;
