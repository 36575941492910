import React from "react";
import "./blogs.scss";
import OurBlogs from "./components/OurBlogs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import headerBG from '../../../assets/header.svg';
import arrow from '../../../assets/arrow.svg';
import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {data} from './blogsdetails/blogsdata';
import instagram from '../../../assets/instagram.svg';
import twitter from '../../../assets/twitter.svg';
import linkedin from '../../../assets/linkedin.svg';
import tiktok from '../../../assets/tiktok.svg';

const Blogs = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    slidesToShow: 3,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <>
    <div className="desktop">
      <div className="blogs">
      <div className="d-flex flex-column h-100 w-100 header-bg">
      <div className="w-75 mt-5 ms-5">
      <div className="header-social w-25">
              <img src={headerBG} alt="header"/>
              <div className="position-absolute-icon d-flex flex-column">
              <a href='https://instagram.com/rayatmarketing?igshid=MzRlODBiNWFlZA==' className='mb-4'>
                <img src={instagram} alt='ig icon'/>
              </a>
              <a href='https://twitter.com/rayatmarketing?s=11&t=ZUlDMnfoVx_5jMItXaxV3w' className='mb-4'>
                <img src={twitter} alt='ig icon'/>
              </a>
              <a href='https://sa.linkedin.com/company/rayatmarketing' className='mb-4'>
                <img src={linkedin} alt='ig icon'/>
              </a>
              <a href='https://www.tiktok.com/@rayatmarketing?_t=8eBfz2KQhxi&_r=1' className='mb-2'>
                <img src={tiktok} alt='ig icon'/>
              </a>
              </div>
            </div>
      </div>
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div className="w-75 d-flex flex-column align-items-start header-text" dir={t("ltr")}>
        <h1>{t("Have an idea or project?")}</h1>
        <h1>{t("lets talk")}</h1>
        <div className="d-flex m-5">
          <img src={arrow} alt='arrow' className={t("ltr")==="rtl"? "transform-img ms-3":''}/>
          <h6 className="ms-3">{t("lets be in touch")}</h6>
        </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      <div className="mobile h-100">
      <div className="blogs">
      <div className="d-flex flex-column h-100 w-100 header-bg">
      <div className="w-75 mt-5 ms-2 ">
        <div className="header-social mt-5 hidden">
          <img src={headerBG} alt="header"/>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div className="w-75 d-flex flex-column align-items-center mt-5 header-text" dir={t("ltr")}>
        <h1>{t("Have an idea or project?")}</h1>
        <h1>{t("lets talk")}</h1>
        <div className="d-flex m-5">
          <img src={arrow} alt='arrow' className={t("ltr")==="rtl"? "transform-img ms-3":''}/>
          <h6 className="ms-3">{t("lets be in touch")}</h6>
        </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      <OurBlogs />
      <div className="w-100 blogs-carousel d-flex justify-content-center align-items-center">
        <div className="w-75 pt-5 pb-5  mt-5">
          <Slider {...settings} className="h-100">
            {data.map((item,index)=>(
               <div className="position-relative d-flex justify-content-center align-items-center" dir={item.lang==="ar"?"rtl":"ltr"}>
               <Link to={`/blogs/${item.id}`}>
                 <img src={item.banner} alt="portfolio" className="w-100" />
                 <div className="blog-text">
                   <h5>{item.name}</h5>
                 </div>
                 </Link>
               </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Blogs;
